import React, { useEffect } from "react";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Chip,
  Box,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QrCodeIcon from "@mui/icons-material/QrCode";
import SearchIcon from "@mui/icons-material/Search";
// import SearchBar from "../../../app/common/form/SearchBar.tsx";
import { useStore } from "../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Product } from "../../../app/models/product.ts";
import axios from "axios";
import { Order } from "../../../app/models/order.ts";
import { formatDate } from "../../../app/utils/dateUtils.js";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { OrderStatus } from '../../../app/models/order.ts';

interface LotInfo {
  lotNumber: string;
  lotId: number;
}

const OrderLookup: React.FC = () => {

  const { productStore } = useStore();
  const {
    loadingFilters,
    loadActiveCategories,
    setNameFilter,
    setPagingParams,
    loadProducts,
    loadActiveManufacturers,
  } = productStore;

  const [options, setOptions] = React.useState<readonly Product[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(
    null
  );
  const [lotOptions, setLotOptions] = React.useState<LotInfo[]>([]);
  const [lotLoading, setLotLoading] = React.useState(false);

  // Separate open states for each Autocomplete
  const [productOpen, setProductOpen] = React.useState(false);
  const [lotOpen, setLotOpen] = React.useState(false);
  const [directLotOpen, setDirectLotOpen] = React.useState(false);

  // Add value states for each Autocomplete
  const [productValue, setProductValue] = React.useState<Product | null>(null);
  const [lotValue, setLotValue] = React.useState<LotInfo | null>(null);
  const [directLotValue, setDirectLotValue] = React.useState<string>('');

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Initialize state from URL parameters
  const [isDirectLotSearch, setIsDirectLotSearch] = React.useState<boolean>(
    searchParams.get('direct') === 'true'
  );
  
  // Load initial product if productId exists in URL
  React.useEffect(() => {
    const direct = searchParams.get('direct') === 'true';
    const productId = searchParams.get('productId');
    const lotNumber = searchParams.get('lotNumber');
    const lotId = searchParams.get('lotId');

    setIsDirectLotSearch(direct);

    if (direct && lotNumber) {
      setDirectLotValue(lotNumber);
      // Trigger the lot number search
      handleLotNumberChange(null as any, lotNumber);
    } else if (productId) {
      loadProductById(parseInt(productId)).then(() => {
        if (lotNumber && lotId) {
          // Set lot value after loading lot numbers
          const lotInfo: LotInfo = {
            lotNumber,
            lotId: parseInt(lotId)
          };
          setLotValue(lotInfo);
          loadOrdersByProductLot(parseInt(lotId));
        }
      });
    }
  }, []); // Run once on mount

  // Update loadProductById to also load lot numbers
  const loadProductById = async (productId: number) => {
    try {
      const response = await axios.get(`/products/${productId}`);
      setProductValue(response.data);
      setSelectedProduct(response.data);
      await loadLotNumbers(productId); // Load lot numbers after setting product
      return response.data;
    } catch (error) {
      console.error('Failed to load product', error);
    }
  };
  // Update handleProductSelect to properly handle product selection and lot loading
  const handleProductSelect = async (event: any, newValue: Product | null) => {
    setProductValue(newValue);
    setSelectedProduct(newValue); // Make sure to set selectedProduct
    
    if (newValue) {
      setSearchParams({
        productId: newValue.id.toString(),
        productName: newValue.name
      });
      await loadLotNumbers(newValue.id);
    } else {
      setSearchParams({});
      setLotOptions([]);
      setLotValue(null);
    }
  };

  // Update URL when lot is selected
  const handleLotSelect = (event: any, selectedLot: LotInfo | null) => {
    setLotValue(selectedLot);
    if (selectedLot) {
      setSearchParams(prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('lotNumber', selectedLot.lotNumber);
        newParams.set('lotId', selectedLot.lotId.toString());
        return newParams;
      });
      loadOrdersByProductLot(selectedLot.lotId);
    }
  };

  // Handle direct lot number search
  const handleLotNumberChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setDirectLotValue(value);
    if (value.length >= 3) {
      setSearchParams({ direct: 'true', lotNumber: value });
      setOrderLoading(true);
      try {
        const response = await axios.get<Order[]>(
          `/ProductLot/orders/by-lot-number/${value}`
        );
        setOrders(response.data);
      } catch (error) {
        console.error("Failed to load orders by lot number", error);
        setOrders([]);
      } finally {
        setOrderLoading(false);
      }
    } else {
      setSearchParams({ direct: 'true' });
      setOrders([]);
    }
  };

  // Handle order selection
  const handleOrderClick = (orderId: number) => {
    // Preserve search parameters when navigating to order details
    const currentParams = Object.fromEntries(searchParams.entries());
    navigate(`/admin/warehouse/order-lookup/${orderId}`, {
      state: { searchParams: currentParams }
    });
  };

  const loadFilteredProducts = async () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: productStore.pagingParams.pageSize,
    });
    setLoading(true);
    await loadProducts();
    setOptions(productStore.products);
    setLoading(false);
  };

  // Update loadLotNumbers to handle the response properly
  const loadLotNumbers = async (productId: number) => {
    setLotLoading(true);
    try {
      const response = await axios.get(`/ProductLot/product/${productId}`);
      const lotData = response.data.map((lot: any) => ({
        lotNumber: lot.lotNumber,
        lotId: lot.id,
      }));
      setLotOptions(lotData);
    } catch (error) {
      console.error("Failed to load lot numbers", error);
      setLotOptions([]);
    } finally {
      setLotLoading(false);
    }
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setNameFilter(value);
    loadFilteredProducts();
  };

  const [orders, setOrders] = React.useState<Order[]>([]);
  const [orderLoading, setOrderLoading] = React.useState(false);

  const loadOrdersByProductLot = async (productLotId: number) => {
    setOrderLoading(true);
    try {
      const response = await axios.get<Order[]>(
        `/ProductLot/orders/${productLotId}`
      );
      // const uniqueOrders = Array.from(
      //   new Map(
      //     response.data
      //       .map(itemLot => itemLot.orderItem.order)
      //       .map(order => [order.id, order])
      //   ).values()
      // );
      setOrders(response.data);
    } catch (error) {
      console.error("Failed to load orders", error);
    } finally {
      setOrderLoading(false);
    }
  };

  // Add this helper function for status labels
  const orderStatusLabel = (status: OrderStatus): string => {
    return OrderStatus[status];
  };

  // Update the status color helper function
  const getStatusColor = (
    status: OrderStatus
  ): "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" => {
    switch (status) {
      case OrderStatus.WaitingForApproval:
        return "warning";
      case OrderStatus.Revoked:
        return "error";
      case OrderStatus.OrderReceived:
        return "info";
      case OrderStatus.Preparing:
        return "primary";
      case OrderStatus.OnHold:
        return "warning";
      case OrderStatus.Shipped:
        return "info";
      case OrderStatus.Delivered:
        return "success";
      case OrderStatus.Delayed:
        return "error";
      case OrderStatus.Approved:
        return "success";
      case OrderStatus.WaitingToShip:
        return "warning";
      default:
        return "default";
    }
  };

  // Add this helper function for status styles
  const getStatusStyle = (status: OrderStatus) => {
    const baseStyles = {
      fontWeight: 'medium',
      fontSize: '0.875rem',
    };

    switch (status) {
      case OrderStatus.WaitingForApproval:
      case OrderStatus.WaitingToShip:
        return {
          ...baseStyles,
          backgroundColor: '#fff3e0',
          color: '#ed6c02',
        };
      case OrderStatus.Revoked:
      case OrderStatus.Delayed:
        return {
          ...baseStyles,
          backgroundColor: '#fde9e9',
          color: '#d32f2f',
        };
      case OrderStatus.OrderReceived:
      case OrderStatus.Shipped:
        return {
          ...baseStyles,
          backgroundColor: '#e3f2fd',
          color: '#0288d1',
        };
      case OrderStatus.Preparing:
        return {
          ...baseStyles,
          backgroundColor: '#e3f2fd',
          color: '#1976d2',
        };
      case OrderStatus.OnHold:
        return {
          ...baseStyles,
          backgroundColor: '#fff3e0',
          color: '#ed6c02',
        };
      case OrderStatus.Delivered:
      case OrderStatus.Approved:
        return {
          ...baseStyles,
          backgroundColor: '#e8f5e9',
          color: '#2e7d32',
        };
      default:
        return {
          ...baseStyles,
          backgroundColor: '#f5f5f5',
          color: '#666666',
        };
    }
  };

  useEffect(() => {
    loadActiveManufacturers();
  }, [loadActiveCategories, loadActiveManufacturers]);

  if (loadingFilters) return <CircularProgress />;

  return (
    <div>
      {/* Page Header */}
      <Paper 
        elevation={0} 
        sx={{ 
          p: 3, 
          mb: 3, 
          backgroundColor: '#f5f5f5',
          borderRadius: 2
        }}
      >
        
        <FormControlLabel
          control={
            <Checkbox
              checked={isDirectLotSearch}
              onChange={(e) => {
                setIsDirectLotSearch(e.target.checked);
                setSelectedProduct(null);
                setProductValue(null);
                setLotValue(null);
                setDirectLotValue('');
                setLotOptions([]);
                setOrders([]);
              }}
            />
          }
          label={
            <Typography variant="body1" sx={{ color: '#666' }}>
              Search directly by lot number
            </Typography>
          }
        />
      </Paper>

      {/* Search Section */}
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          {!isDirectLotSearch ? (
            <>
              <Autocomplete
                sx={{ 
                  width: { xs: '100%', sm: 300 },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#1976d2',
                    },
                  }
                }}
                open={productOpen}
                onOpen={() => setProductOpen(true)}
                onClose={() => setProductOpen(false)}
                value={productValue}
                onChange={(event, newValue) => {
                  setProductValue(newValue);
                  handleProductSelect(event, newValue);
                }}
                onInputChange={(event, value) =>
                  handleNameChange(event as any, value)
                }
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                getOptionLabel={(option) => option ? `${option.name} (${option.code})` : ''}
                options={options}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Products"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <SearchIcon sx={{ color: '#666', mr: 1 }} />
                      ),
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {selectedProduct && (
                <Autocomplete
                  sx={{ 
                    width: { xs: '100%', sm: 300 },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#1976d2',
                      },
                    }
                  }}
                  open={lotOpen}
                  onOpen={() => setLotOpen(true)}
                  onClose={() => setLotOpen(false)}
                  value={lotValue}
                  onChange={(event, newValue) => {
                    setLotValue(newValue);
                    handleLotSelect(event, newValue);
                  }}
                  options={lotOptions}
                  loading={lotLoading}
                  getOptionLabel={(option: LotInfo) => option ? option.lotNumber : ''}
                  isOptionEqualToValue={(option, value) => 
                    option.lotId === value.lotId && option.lotNumber === value.lotNumber
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Lot Number"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <QrCodeIcon sx={{ color: '#666', mr: 1 }} />
                        ),
                        endAdornment: (
                          <>
                            {lotLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </>
          ) : (
            <Autocomplete
              sx={{ 
                width: { xs: '100%', sm: 300 },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#1976d2',
                  },
                }
              }}
              freeSolo
              open={directLotOpen}
              onOpen={() => setDirectLotOpen(true)}
              onClose={() => setDirectLotOpen(false)}
              value={directLotValue}
              onChange={(event, newValue) => {
                setDirectLotValue(newValue || '');
              }}
              onInputChange={(event, value) => {
                setDirectLotValue(value);
                handleLotNumberChange(event as any, value);
              }}
              options={[]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search by Lot Number"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <QrCodeIcon sx={{ color: '#666', mr: 1 }} />
                    ),
                    endAdornment: (
                      <>
                        {orderLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        </div>
      </Paper>

      {/* Results Section */}
      {orders.length > 0 && (
        <Paper sx={{ borderRadius: 2, overflow: 'hidden' }}>
          {orderLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
                <Typography variant="h6" sx={{ color: '#1976d2' }}>
                  Search Results ({orders.length} orders found)
                </Typography>
              </Box>
              <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead sx={{ bgcolor: "#1976d2" }}>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Order Number
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Order Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Client
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Sales Rep.
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Sub Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Items
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Shipment Date
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" sx={{ color: "white", fontWeight: 'bold' }}>
                          Order Items
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow 
                        key={order.id}
                        onClick={() => handleOrderClick(order.id)}
                        sx={{ 
                          '&:hover': { 
                            backgroundColor: '#f5f5f5',
                            cursor: 'pointer'
                          }
                        }}
                      >
                        <TableCell>{order.orderNumber}</TableCell>
                        <TableCell>{formatDate(order.orderDate)}</TableCell>
                        <TableCell>{order.client.name}</TableCell>
                        <TableCell>{order.userName}</TableCell>
                        <TableCell>
                          ${order.subTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell>{order.itemsCount}</TableCell>
                        <TableCell>
                          <Chip 
                            label={orderStatusLabel(order.status as OrderStatus)}
                            color={getStatusColor(order.status as OrderStatus)}
                            size="small"
                            sx={{
                              ...getStatusStyle(order.status as OrderStatus),
                              '& .MuiChip-label': {
                                fontWeight: 500,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {order.shipmentDate ? formatDate(order.shipmentDate) : 'Not Available'}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            component={Link}
                            to={`/admin/warehouse/order-lookup/${order.id}`}
                            size="small"
                            sx={{ 
                              color: '#1976d2',
                              '&:hover': { 
                                backgroundColor: 'rgba(25, 118, 210, 0.04)'
                              }
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      )}
    </div>
  );
};

export default observer(OrderLookup);
