import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, CircularProgress, FormControlLabel, Grid, Switch } from "@mui/material";
import * as Yup from "yup";
import { TaxFormValues } from "../../../../app/models/tax.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../../../app/common/form/MyTextInput.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import MyBoolInput from "../../../../app/common/form/MyBoolInput.tsx";

const enum DISPLAY_NAMES {
  name = "Name",
  code = "Code",
  isIncluded = "Included",
  isRefunded = "Refunded",
  rate = "Rate"
}

const enum FIELD_NAMES {
  name = "name",
  code = "code",
  isIncluded = "isIncluded",
  isRefunded = "isRefunded",
  rate = "rate"
}

const TaxForm: React.FC = () => {
  const navigate = useNavigate();
  const { taxStore } = useStore();
  const {
    editMode,
    loadingInitial,
    pagingParams,
    setPagingParams,
    loadTaxById,
    updateTax,
    saveTax,
    setEditMode,
  } = taxStore;
  const [tax, setTax] =
    useState<TaxFormValues | null>(null);

  const { id } = useParams();

  const navigateToHome = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    navigate("/admin/taxes");
  };

  const handleCreateOrEditTax = (
    tax: TaxFormValues
  ) => {
    if (tax.id) {
      updateTax(tax).then(() => navigateToHome());
    } else {
      tax.id = 0;
      saveTax(tax).then(() => navigateToHome());
    }
  };

  useEffect(() => {
    if (id) {
      loadTaxById(parseFloat(id!)).then((tax) => {
        setTax(new TaxFormValues(tax));
      });
      setEditMode(true);
    } else {
      setTax(new TaxFormValues());
      setEditMode(false);
    }
  }, [id, loadTaxById, setEditMode]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    isIncluded: Yup.boolean().required("Required"),
    isRefunded: Yup.boolean().required("Required"),
    rate: Yup.number().required("Required")
  });

  if (editMode && (loadingInitial || !tax))
    return <CircularProgress />;

  return (
    tax && (
      <>
        <h1>{editMode ? "Edit Tax" : "Add Tax"}</h1>
        <Formik
          initialValues={tax}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleCreateOrEditTax(values);
          }}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.name}
                    label={DISPLAY_NAMES.name}
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.code}
                    label={DISPLAY_NAMES.code}
                  />
                </Grid>
                <Grid item>
                  <MyBoolInput name={FIELD_NAMES.isIncluded} label={DISPLAY_NAMES.isIncluded} />
                </Grid>
                <Grid item>
                  <MyBoolInput name={FIELD_NAMES.isRefunded} label={DISPLAY_NAMES.isRefunded} />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.rate}
                    label={DISPLAY_NAMES.rate}
                    type="number"
                  />
                </Grid>

                <Grid item container spacing={8}>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {editMode ? "Save" : "Add"}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      component={Link}
                      to={`/admin/taxes`}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    )
  );
};

export default observer(TaxForm);
