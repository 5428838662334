import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store.ts";
import { Link } from "react-router-dom";
import { Product } from "../../../../app/models/product";
import { Route, Routes } from "react-router-dom";
import InventoryProductDetails from "./ProductDetails.tsx";

const InventoryControl: React.FC = () => {
  const { productStore } = useStore();
  const {
    products,
    pagination,
    loadProducts,
    updateProduct,
    setPagingParams,
    loadActiveCategories,
    loadActiveManufacturers,
    setNameFilter,
    setIsActiveFilter,
    setCategoryIdFilter,
    setManufacturerIdFilter,
    deleteProduct,
    toggleActive,
    categories,
  } = productStore;

  const [editingId, setEditingId] = useState<number>();
  const [editedProduct, setEditedProduct] = useState<Product>();

  const [nameFilter, setLocalNameFilter] = useState("");
  const [isActiveFilter, setLocalIsActiveFilter] = useState(true);
  const [categoryIdFilter, setLocalCategoryIdFilter] = useState("");
  const [manufacturerIdFilter, setLocalManufacturerIdFilter] = useState("");

  useEffect(() => {
    productStore.reset();
    
    loadProducts();
    loadActiveCategories();
    loadActiveManufacturers();

    return () => {
      productStore.reset();
    };
  }, [loadProducts, loadActiveCategories, loadActiveManufacturers, productStore]);

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagingParams({
      pageNumber: value,
      pageSize: pagination?.itemsPerPage || 10,
    });
    loadProducts();
  };

  const handleDelete = (id: number) => {
    deleteProduct(id).then(() => loadProducts());
  };

  const handleToggle = (id: number) => {
    toggleActive(id).then(() => loadProducts());
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalNameFilter(event.target.value);
    setNameFilter(event.target.value);
    loadProducts();
  };

  const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalIsActiveFilter(event.target.checked);
    setIsActiveFilter(event.target.checked);
    loadProducts();
  };

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    setLocalCategoryIdFilter(event.target.value);
    setCategoryIdFilter(event.target.value);
    loadProducts();
  };

  const handleManufacturerChange = (event: SelectChangeEvent<string>) => {
    setLocalManufacturerIdFilter(event.target.value);
    setManufacturerIdFilter(event.target.value);
    loadProducts();
  };

  const handleEdit = (product) => {
    setEditingId(product.id);
    setEditedProduct(product);
  };

  const handleSave = async () => {
    if (!editedProduct) return;

    const formData = new FormData();

    // Add all fields to formData
    Object.keys(editedProduct).forEach((key) => {
      if (key !== "category" && key !== "manufacturer" && key !== "image") {
        formData.append(key, editedProduct[key]);
      }
    });

    // Handle image separately if it exists
    if (editedProduct.image) {
      formData.append("image", editedProduct.image);
    }

    await updateProduct(editedProduct.id, formData);
    setEditingId(null);
    setEditedProduct(null);
    loadProducts();
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditedProduct(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedProduct({ ...editedProduct, [name]: value });
  };

  if (productStore.loadingInitial) return <CircularProgress />;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box display="flex" alignItems="center">
                <TextField
                  label="Search by name"
                  value={nameFilter}
                  onChange={handleNameChange}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: "10px" }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActiveFilter}
                      onChange={handleIsActiveChange}
                    />
                  }
                  label="Active"
                />
                <FormControl
                  size="small"
                  style={{ minWidth: 120, marginRight: "10px" }}
                >
                  <InputLabel>Manufacturer</InputLabel>
                  <Select
                    value={manufacturerIdFilter}
                    onChange={handleManufacturerChange}
                    label="Manufacturer"
                  >
                    <MenuItem value="">All</MenuItem>
                    {productStore.manufacturers?.map((manufacturer) => (
                      <MenuItem key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl size="small" style={{ minWidth: 120 }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={categoryIdFilter}
                    onChange={handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">All</MenuItem>
                    {productStore.categories?.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Button
                component={Link}
                to="/admin/warehouse/inventory-control/create"
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add new product
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="inventory table">
                <TableHead sx={{ bgcolor: "#525252" }}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Image
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Code
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Unit Price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Unit Weight
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Items in Case
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Case Price
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Active
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Category
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Actions
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ color: "white" }}>
                        Add/Edit Quantities
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow
                      key={product.id}
                      sx={{
                        "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
                      }}
                    >
                      <TableCell>
                        <img
                          src={`${process.env.REACT_APP_API_ROOT}${product.imageUrl}`}
                          alt={product.name}
                          style={{ width: 50 }}
                        />
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="name"
                            value={editedProduct.name}
                            onChange={handleChange}
                            size="small"
                          />
                        ) : (
                          product.name
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="code"
                            value={editedProduct.code}
                            onChange={handleChange}
                            size="small"
                          />
                        ) : (
                          product.code
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="unitPrice"
                            value={editedProduct.unitPrice}
                            onChange={handleChange}
                            size="small"
                            type="number"
                          />
                        ) : (
                          `$${product.unitPrice}`
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="unitWeight"
                            value={editedProduct.unitWeight}
                            onChange={handleChange}
                            size="small"
                            type="number"
                          />
                        ) : (
                          `${product.unitWeight}g`
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="itemsInCase"
                            value={editedProduct.itemsInCase}
                            onChange={handleChange}
                            size="small"
                            type="number"
                          />
                        ) : (
                          product.itemsInCase
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <TextField
                            name="casePrice"
                            value={editedProduct.casePrice}
                            onChange={handleChange}
                            size="small"
                            type="number"
                          />
                        ) : (
                          `$${product.casePrice}`
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <Switch
                            checked={editedProduct?.isActive ?? false}
                            onChange={(e) => {
                              if (editedProduct) {
                                setEditedProduct({
                                  ...editedProduct,
                                  isActive: e.target.checked,
                                });
                              }
                            }}
                          />
                        ) : (
                          product.isActive.toString()
                        )}
                      </TableCell>
                      <TableCell>
                        {editingId === product.id ? (
                          <FormControl fullWidth size="small">
                            <InputLabel>Category</InputLabel>
                            <Select
                              value={editedProduct.categoryId}
                              onChange={(e) =>
                                setEditedProduct({
                                  ...editedProduct,
                                  categoryId: e.target.value,
                                })
                              }
                              label="Category"
                            >
                              {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          product.category.name
                        )}
                      </TableCell>

                      <TableCell>
                        {editingId === product.id ? (
                          <>
                            <IconButton onClick={handleSave}>
                              <SaveIcon />
                            </IconButton>
                            <IconButton onClick={handleCancel}>
                              <CancelIcon />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton onClick={() => handleEdit(product)}>
                            <EditIcon />
                          </IconButton>
                        )}
                        <IconButton onClick={() => handleDelete(product.id)}>
                          <DeleteIcon />
                        </IconButton>
                        {/* <IconButton
                          component={Link}
                          to={`/admin/warehouse/inventory-control/edit/${product.id}`}
                        >
                          <OpenInNewIcon />
                        </IconButton> */}
                        <IconButton onClick={() => handleToggle(product.id)}>
                          <SwapHorizIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          component={Link}
                          to={`/admin/warehouse/inventory-control/edit/${product.id}`}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Pagination
                count={pagination ? pagination.totalPages : 0}
                page={pagination ? pagination.currentPage : 1}
                onChange={handlePageChange}
              />
            </Box>
          </div>
        }
      />
      <Route path=":id" element={<InventoryProductDetails />} />
    </Routes>
  );
};

export default observer(InventoryControl);
