import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Pagination,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface OrderItemListProps {
  orderId: number;
  orderApproved: boolean; // Pass this as a prop to determine if editing and deleting are allowed
}

const MyOrderItemList: React.FC<OrderItemListProps> = ({
  orderId,
  orderApproved,
}) => {
  const { orderStore } = useStore();
  const {
    loadOrderItems,
    setPagingParams,
    pagination,
    orderItems,
    pagingParams,
    loadingInitial,
    updateOrderItem,
    deleteOrderItem,
  } = orderStore;

  const [editableItem, setEditableItem] = useState<number | null>(null);
  const [editedQuantity, setEditedQuantity] = useState<number>(0);

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrderItems(orderId);
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  useEffect(() => {
    loadOrderItems(orderId);
  }, [loadOrderItems, orderId]);

  if (loadingInitial) return <CircularProgress />;

  const handleEditClick = (
    itemId: number | undefined,
    currentQuantity: number
  ) => {
    if (itemId) {
      // Ensure itemId is not undefined
      setEditableItem(itemId);
      setEditedQuantity(currentQuantity);
    }
  };

  const handleSaveClick = async (orderItem: any) => {
    if (orderItem.id) {
      // Ensure orderItem.id is not undefined
      if (editedQuantity === 0) {
        try {
          await deleteOrderItem(orderId, orderItem.id); // Pass both orderId and itemId
          setEditableItem(null);
          loadOrderItems(orderId); // Reload order items after deletion
        } catch (error) {
          console.error("Failed to delete order item", error);
        }
      } else {
        const updatedOrderItem = {
          ...orderItem,
          quantity: editedQuantity,
          totalPrice: orderItem.unitPrice * editedQuantity,
        };
        try {
          await updateOrderItem(updatedOrderItem); // Call the function to update the item
          setEditableItem(null);
          loadOrderItems(orderId); // Reload order items after update
        } catch (error) {
          console.error("Failed to update order item", error);
        }
      }
    } else {
      console.error("Order item ID is undefined");
    }
  };

  const handleCancelClick = () => {
    setEditableItem(null);
  };

  const handleDeleteClick = async (itemId: number | undefined) => {
    if (itemId) {
      // Ensure itemId is not undefined
      await deleteOrderItem(orderId, itemId); // Call the store action to delete the item
      loadOrderItems(orderId); // Refresh the order items after deletion
    } else {
      console.error("Order item ID is undefined");
    }
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Image
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Product Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Product Code
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Quantity
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Unit Price
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Total Price
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Case / Unit
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems &&
              orderItems.map((orderItem) => (
                <TableRow
                  key={orderItem.id}
                  sx={{
                    backgroundColor: Array.from(
                      orderStore.highlightedItems
                    ).some(
                      (highlightedItem) =>
                        highlightedItem.id === orderItem.product.id &&
                        highlightedItem.byCase === orderItem.byCase
                    )
                      ? "rgba(237, 233, 157, 1)"
                      : "inherit",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell>
                    <img
                      src={`${process.env.REACT_APP_API_ROOT}${orderItem.product.imageUrl}`}
                      alt={orderItem.product.name}
                      style={{ width: 90 }}
                    />
                  </TableCell>
                  <TableCell>{orderItem.product.name}</TableCell>
                  <TableCell>{orderItem.product.code}</TableCell>
                  <TableCell>
                    {editableItem === orderItem.id && !orderApproved ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          type="number"
                          value={editedQuantity}
                          onChange={(e) =>
                            setEditedQuantity(Number(e.target.value))
                          }
                          size="small"
                          sx={{ maxWidth: 90 }}
                          inputProps={{ min: 0, max: 999, step: 1 }}
                        />
                        <IconButton
                          onClick={() => handleSaveClick(orderItem)}
                          size="small"
                        >
                          <SaveIcon />
                        </IconButton>
                        <IconButton onClick={handleCancelClick} size="small">
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box>
                        {orderItem.quantity}
                        {!orderApproved && (
                          <IconButton
                            onClick={() =>
                              handleEditClick(orderItem.id, orderItem.quantity)
                            }
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>${orderItem.unitPrice}</TableCell>
                  <TableCell>${orderItem.totalPrice}</TableCell>
                  <TableCell>{orderItem.byCase ? "Case" : "Unit"}</TableCell>
                  <TableCell>
                    {!orderApproved && (
                      <>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDeleteClick(orderItem.id)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleEditClick(orderItem.id, orderItem.quantity)
                          }
                          size="small"
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default observer(MyOrderItemList);
