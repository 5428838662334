import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { OrderStatus } from "../../../../app/models/order.ts";
import agent from '../../../../app/api/agent.ts';

const OrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrdersToPrepareShipment,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    // updateOrderStatus,
  } = orderStore;

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrdersToPrepareShipment();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  // const handleStatusChange = async (orderId: number, newStatus: OrderStatus) => {
  //   await updateOrderStatus(orderId, newStatus);
  //   loadOrdersToPrepareShipment();
  // };

  const [isAssigning, setIsAssigning] = useState<{ [key: number]: boolean }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [outOfStockDialog, setOutOfStockDialog] = useState({
    open: false,
    items: [] as any[]
  });

  const handleAssignLots = async (orderId: number) => {
    if (isAssigning[orderId]) return;
    
    try {
      setIsAssigning(prev => ({ ...prev, [orderId]: true }));
      
      const order = orders.find(o => o.id === orderId);
      if (order?.status === OrderStatus.OutOfStock) {
        const stockCheckResult = await orderStore.checkOrderStock(orderId);
        
        if (!stockCheckResult.success) {
          setOutOfStockDialog({
            open: true,
            items: stockCheckResult.outOfStockItems
          });
          return;
        }
      }
      
      await agent.Orders.assignLots(orderId);
      setSuccessMessage('Lots assigned successfully');
      loadOrdersToPrepareShipment();
    } catch (error: any) {      
      if (error.response?.data) {
        if (typeof error.response.data === 'string') {
          setErrorMessage(error.response.data);
        }
      }
    } finally {
      setIsAssigning(prev => ({ ...prev, [orderId]: false }));
    }
  };

  const handleCloseDialog = () => {
    setOutOfStockDialog({
      open: false,
      items: []
    });
  };

  useEffect(() => {
    loadOrdersToPrepareShipment();
  }, [loadOrdersToPrepareShipment]);

  if (loadingInitial) return <CircularProgress />;

  return (
    <div>

      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setErrorMessage(null)} 
          severity="error" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={3000} 
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSuccessMessage(null)} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Client
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  User
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Sub Total
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Items
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Prepare Shipment
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Prepare Order
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{formatDate(order.orderDate)}</TableCell>
                  <TableCell>{order.client.name}</TableCell>
                  <TableCell>{order.userName}</TableCell>
                  <TableCell>${order.subTotal}</TableCell>
                  <TableCell>{order.itemsCount}</TableCell>
                  <TableCell>
                  {OrderStatus[order.status]}
                  </TableCell>
                  <TableCell>
                    <IconButton component={Link} to={`/admin/warehouse/prepare-shipment/${order.id}`}
                      disabled={order.status === OrderStatus.Approved || order.status===OrderStatus.OutOfStock}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={isAssigning[order.id] ? <CircularProgress size={20} color="inherit" /> : <AssignmentIcon />}
                      onClick={() => handleAssignLots(order.id)}
                      disabled={!(order.status === OrderStatus.Approved || order.status === OrderStatus.OutOfStock) || isAssigning[order.id]}
                    >
                      {isAssigning[order.id] ? 'Preparing...' : 'Prepare Order'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>

      <Dialog 
        open={outOfStockDialog.open} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle 
          sx={{ 
            bgcolor: '#525252', 
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              Insufficient Stock Alert
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
            The following items don't have sufficient stock to fulfill the order:
          </Typography>
          <List>
            {outOfStockDialog.items.map((item, index) => (
              <ListItem 
                key={index}
                sx={{
                  mb: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  backgroundColor: '#f8f8f8'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="h6" color="primary">
                      {item.product.name}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Code: {item.product.code}
                    </Typography>
                  </Box>
                  
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#e3f2fd',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Requested
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {item.quantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#fff3e0',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Available
                        </Typography>
                        <Typography variant="h6" color="warning.main">
                          {item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#ffebee',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Shortage
                        </Typography>
                        <Typography variant="h6" color="error">
                          {item.quantity - item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid #e0e0e0' }}>
          <Button 
            onClick={handleCloseDialog} 
            variant="contained"
            size="large"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(OrderList);
