import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Pagination,
  Button,
  Grid,
  Chip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store.ts";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OrderLookupOrderItems: React.FC = () => {
  const { orderStore } = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = location.state?.searchParams;
  const { orderId } = useParams<{ orderId: string }>();

  const {
    loadOrderItems,
    setPagingParams,
    pagination,
    orderItems,
    pagingParams,
    loadingInitial,
  } = orderStore;

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPagingParams({
      pageNumber: value,
      pageSize: pagingParams.pageSize,
    });
    loadOrderItems(Number(orderId));
  };

  useEffect(() => {
    loadOrderItems(Number(orderId));
  }, [loadOrderItems, orderId]);

  if (loadingInitial) return <CircularProgress />;

  // Display order information
  const orderInfo = orderItems.length > 0 ? orderItems[0].order : null;
  const handleBack = () => {
    // Navigate back to order lookup with preserved search parameters
    navigate('/admin/warehouse/order-lookup?' + new URLSearchParams(searchParams).toString());
  };
  
  return (
    <Box sx={{ p: 3, maxWidth: '1200px', margin: '0 auto' }}>
      {/* Back Button */}
      <Button 
        onClick={handleBack} 
        startIcon={<ArrowBackIcon />}
        variant="outlined"
        sx={{ mb: 3 }}
      >
        Back to Search Results
      </Button>

      {/* Order Information */}
      {orderInfo && (
        <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#2c3e50' }}>
            Order Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Order Number:</strong> {orderInfo.orderNumber}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Order Date:</strong> {new Date(orderInfo.orderDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Shipment Date:</strong> {orderInfo.shipmentDate ? new Date(orderInfo.shipmentDate).toLocaleDateString() : 'Not shipped yet'}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Client Name:</strong> {orderInfo.client.name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Sales Rep:</strong> {orderInfo.userName}
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Subtotal:</strong> ${orderInfo.subTotal.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* Order Items Section */}
      <Paper elevation={2} sx={{ mb: 3 }}>
        <Box sx={{ p: 2, bgcolor: '#f5f5f5' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2c3e50' }}>
            Order Items
          </Typography>
        </Box>
        
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="order items table">
            <TableHead sx={{ bgcolor: '#1976d2' }}>
              <TableRow>
                {['Image', 'Product Name', 'Product Code', 'Quantity', 'Unit Price', 'Total Price', 'Case / Unit'].map((header) => (
                  <TableCell key={header}>
                    <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 'bold' }}>
                      {header}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderItems && orderItems.map((orderItem) => (
                <TableRow
                  key={orderItem.id}
                  sx={{
                    backgroundColor: Array.from(orderStore.highlightedItems).some(
                      (highlightedItem) =>
                        highlightedItem.id === orderItem.product.id &&
                        highlightedItem.byCase === orderItem.byCase
                    )
                      ? 'rgba(237, 233, 157, 0.3)'
                      : 'inherit',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                  }}
                >
                  <TableCell>
                    <Box
                      component="img"
                      src={`${process.env.REACT_APP_API_ROOT}${orderItem.product.imageUrl}`}
                      alt={orderItem.product.name}
                      sx={{ 
                        width: 90,
                        height: 90,
                        objectFit: 'contain',
                        borderRadius: 1
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'medium' }}>{orderItem.product.name}</TableCell>
                  <TableCell>{orderItem.product.code}</TableCell>
                  <TableCell>{orderItem.quantity}</TableCell>
                  <TableCell>${orderItem.unitPrice.toFixed(2)}</TableCell>
                  <TableCell>${orderItem.totalPrice.toFixed(2)}</TableCell>
                  <TableCell>
                    <Chip 
                      label={orderItem.byCase ? "Case" : "Unit"}
                      color={orderItem.byCase ? "primary" : "default"}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </Box>
  );
};

export default observer(OrderLookupOrderItems);
