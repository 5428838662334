import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Button, CircularProgress, Grid } from "@mui/material";
import * as Yup from "yup";
import { TaxMappingFormValues } from "../../../../app/models/taxMapping.ts";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyTextInput from "../../../../app/common/form/MyTextInput.tsx";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";

const enum DISPLAY_NAMES {
  provinceId = "Province",
  categoryId = "Category",
  taxId = "Tax",
  overrideRate = "Override Rate"
}

const enum FIELD_NAMES {
  provinceId = "provinceId",
  categoryId = "categoryId",
  taxId = "taxId",
  overrideRate = "overrideRate"
}

const TaxMappingForm: React.FC = () => {
  const navigate = useNavigate();
  const { taxMappingStore } = useStore();
  const {
    editMode,
    categories,
    provinces,
    taxes,
    loadingInitial,
    loadingFilters,
    pagingParams,
    setPagingParams,
    loadActiveCategories,
    loadProvinces,
    loadTaxes,
    loadTaxMappingById,
    updateTaxMapping,
    saveTaxMapping,
    setEditMode,
  } = taxMappingStore;
  const [taxMapping, setTaxMapping] =
    useState<TaxMappingFormValues | null>(null);

  const { id } = useParams();

  const navigateToHome = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    navigate("/admin/tax-mappings");
  };

  const handleCreateOrEditTaxMapping = (
    taxMapping: TaxMappingFormValues
  ) => {
    if (taxMapping.id) {
      updateTaxMapping(taxMapping).then(() => navigateToHome());
    } else {
      taxMapping.id = 0;
      saveTaxMapping(taxMapping).then(() => navigateToHome());
    }
  };

  useEffect(() => {
    loadActiveCategories();
    loadProvinces();
    loadTaxes();
    if (id) {
      loadTaxMappingById(parseFloat(id!)).then((taxMapping) => {
        setTaxMapping(new TaxMappingFormValues(taxMapping));
      });
      setEditMode(true);
    } else {
      setTaxMapping(new TaxMappingFormValues());
      setEditMode(false);
    }
  }, [id, loadTaxMappingById, setEditMode]);

  const validationSchema = Yup.object({
    provinceId: Yup.number().required("Required"),
    categoryId: Yup.string().required("Required"),
    taxId: Yup.number().required("Required"),
    overrideRate: Yup.number()
  });

  if (editMode && (loadingInitial || !taxMapping))
    return <CircularProgress />;

  return (
    taxMapping && !loadingFilters && (
      <>
        <h1>{editMode ? "Edit Tax Mapping" : "Add Tax Mapping"}</h1>
        <Formik
          initialValues={taxMapping}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleCreateOrEditTaxMapping(values);
          }}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} direction="column">
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.categoryId}
                    label={DISPLAY_NAMES.categoryId}
                    select
                    list={categories}
                    defaultValue={
                      (editMode ? taxMapping.categoryId : undefined)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.provinceId}
                    label={DISPLAY_NAMES.provinceId}
                    select
                    list={provinces}
                    defaultValue={
                      (editMode ? taxMapping.provinceId!.toString() : undefined)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.taxId}
                    label={DISPLAY_NAMES.taxId}
                    select
                    list={taxes}
                    defaultValue={
                      (editMode ? taxMapping.taxId!.toString() : undefined)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <MyTextInput
                    name={FIELD_NAMES.overrideRate}
                    label={DISPLAY_NAMES.overrideRate}
                  />
                </Grid>
                <Grid item container spacing={8}>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || !dirty || !isValid}
                    >
                      {editMode ? "Save" : "Add"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="error"
                      component={Link}
                      to={`/admin/tax-mappings`}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </>
    )
  );
};

export default observer(TaxMappingForm);
