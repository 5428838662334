import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";

const TaxDetails: React.FC<{}> = () => {
  const { taxStore } = useStore();
  const { loadTaxById, loadingInitial, selectedTax } =
    taxStore;

  const { id } = useParams();

  useEffect(() => {
    const loadTaxDetails = async () => {
      await loadTaxById(parseFloat(id!));
    };

    if (id) {
      loadTaxDetails();
    }
  }, [id, loadTaxById]);

  if (loadingInitial || !selectedTax) return <CircularProgress />;

  const { name, code, isIncluded, isRefunded, rate } = selectedTax;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Tax Details
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Name: {name}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Code: {code}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Included: {isIncluded.toString()}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Refunded: {isRefunded.toString()}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Rate: {rate.toFixed(4)}
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/admin/taxes/edit/${id}`}
            sx={{ marginTop: 2 }}
          >
            Edit Tax
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default observer(TaxDetails);