import { Client } from "./client";
import { OrderItem } from "./orderItem";
import { AppUser } from "./user";

// Enum with integer values for backend compatibility
export enum OrderStatus {
  WaitingForApproval = 0, // 0 means "Waiting for approval"
  Revoked = 1, // 1 means "Revoked"
  OrderReceived = 2, // 2 means "Order received"
  Preparing = 3, // 3 means "Preparing"
  OnHold = 4, // 4 means "On hold"
  Shipped = 5, // 5 means "Shipped"
  Delivered = 6, // 6 means "Delivered"
  Delayed = 7, // 7 means "Delayed"
  Approved = 8, // 8 means "Approved",
  WaitingToShip=9,
  OutOfStock = 10
}

export interface IOrder {
  id?: number;
  orderNumber: string;
  orderDate: Date;
  clientId: number;
  client: Client;
  userName: string;
  user?: AppUser;
  userId?: string;
  subTotal: number;
  itemsCount: number;
  orderItems: OrderItem[];
  notes?: string;
  status: number;
  shipmentDate?: Date;
}

// Class representing an order
export class Order implements IOrder {
  id?: number;
  orderNumber: string;
  orderDate: Date;
  clientId: number;
  client: Client;
  userName: string;
  user?: AppUser;
  userId?: string;
  subTotal: number;
  itemsCount: number;
  orderItems: OrderItem[];
  notes?: string;
  status: number;
  shipmentDate?: Date;
  constructor(init?: IOrder) {
    Object.assign(this, init);
  }
}