import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";

const TaxMappingDetails: React.FC<{}> = () => {
  const { taxMappingStore } = useStore();
  const { loadTaxMappingById, loadingInitial, selectedTaxMapping } =
    taxMappingStore;

  const { id } = useParams();

  useEffect(() => {
    const loadTaxMappingDetails = async () => {
      await loadTaxMappingById(parseFloat(id!));
    };

    if (id) {
      loadTaxMappingDetails();
    }
  }, [id, loadTaxMappingById]);

  if (loadingInitial || !selectedTaxMapping) return <CircularProgress />;

  const { category, province, tax, overrideRate } = selectedTaxMapping;

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Tax Mapping Details
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={32} sm={12}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Category: {category.name}
              </Typography>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Province: {province.name}
              </Typography>
            </Grid>
            <Grid item xs={32} sm={12}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Tax: {tax.name} ({tax.rate.toFixed(4)} %)
              </Typography>
              { overrideRate !== undefined && overrideRate !== null &&
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Override Rate: {overrideRate.toFixed(4)} %
                </Typography>
              }
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`/admin/tax-mappings/edit/${id}`}
            sx={{ marginTop: 2 }}
          >
            Edit Tax Mapping
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default observer(TaxMappingDetails);
