import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import SearchBar from "../../../../app/common/form/SearchBar.tsx";
import "./styles.css";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";

const TaxMappingFilters: React.FC = () => {
  const { taxMappingStore } = useStore();

  const {
    categoryNameFilter,
    provinceNameFilter,
    taxNameFilter,
    setCategoryNameFilter,
    setProvinceNameFilter,
    setTaxNameFilter,
    setPagingParams,
    loadTaxMappings,
  } = taxMappingStore;

  const loadFilteredTaxMappings = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: taxMappingStore.pagingParams.pageSize,
    });
    loadTaxMappings();
  };

  const handleCategoryNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryNameFilter(event.target.value);
    loadFilteredTaxMappings();
  };

  const handleProvinceNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProvinceNameFilter(event.target.value);
    loadFilteredTaxMappings();
  };

  const handleTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaxNameFilter(event.target.value);
    loadFilteredTaxMappings();
  };

  return (
    <div>
      <div className="tax-mapping-filters">
        <SearchBar
          label="Search by category"
          value={categoryNameFilter}
          onChange={handleCategoryNameChange}
          className="search-bar"
        />
        <SearchBar
          label="Search by province"
          value={provinceNameFilter}
          onChange={handleProvinceNameChange}
          className="search-bar"
        />
        <SearchBar
          label="Search by tax"
          value={taxNameFilter}
          onChange={handleTaxNameChange}
          className="search-bar"
        />
      </div>
    </div>
  );
};

export default observer(TaxMappingFilters);