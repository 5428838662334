import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button, //Imported Button @riniyad
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { Order } from "../../../../app/models/order.ts";
//changes done by riniyad : Change request : Tony
//Funtionality Added : User Can delete unapproved Orders
//changes done in the table : marked by comment "@riniyad"
const MyOrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadMyOrders,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    deleteOrder, // @Riniyad Added this line to import the delete function from store
  } = orderStore;
  const navigate = useNavigate();

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadMyOrders();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleDeleteOrder = (orderId: number) => {
    deleteOrder(orderId).then(() => {
      loadMyOrders();
    });
  };
  const handleAddItemstoOrder = (order: Order) => {
    const serializedOrder = JSON.parse(JSON.stringify(order));
    navigate(
      `/user/addorderitem?orderId=${order.id}&clientId=${order.client.id} `,
      { state: { order: serializedOrder } }
    );
  };
  const orderStatusLabel = (status) => {
    console.log(status);
    switch (status) {
      case 0:
        return "Waiting for Approval";
      case 1:
        return "Revoked";
      case 2:
        return "Order Received";
      case 3:
        return "Preparing";
      case 4:
        return "On Hold";
      case 5:
        return "Shipped";
      case 6:
        return "Delivered";
      case 7:
        return "Delayed";
      case 8:
        return "Approved";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    loadMyOrders();
  }, [loadMyOrders]);

  if (loadingInitial) return <CircularProgress />;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Client
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Sub Total
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Items
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Items
                </Typography>
              </TableCell>
              {/* Conditionally render the Delete Order header @riniyad*/}
              {orders.some((order) => order.status === 0) && (
                <TableCell>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Delete Order
                  </Typography>
                </TableCell>
              )}
              {/* Conditionally render the Delete Order header @riniyad*/}
              {orders.some((order) => order.status === 0) && (
                <TableCell>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Edit Order
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{formatDate(order.orderDate)}</TableCell>
                  <TableCell>{order.client.name}</TableCell>
                  <TableCell>${order.subTotal}</TableCell>
                  <TableCell>{order.itemsCount}</TableCell>
                  <TableCell>{orderStatusLabel(order.status)}</TableCell>
                  <TableCell>
                    <IconButton component={Link} to={`${order.id}`}>
                      <OpenInNewIcon />
                    </IconButton>
                  </TableCell>
                  {/* Conditionally render the Delete button @Riniyad */}
                  {order.status === 0 && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteOrder(order.id!)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  )}
                  {/* Conditionally render the additems button @Riniyad */}
                  {order.status === 0 && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleAddItemstoOrder(order)}
                      >
                        Add Items
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default observer(MyOrderList);
