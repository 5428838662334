import React, { useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../../app/stores/store.ts";
import MyOrderItemList from "./MyOrderItemList.tsx";

const OrderItemDashboard: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { orderStore } = useStore();
  const { loadOrderById, setPagingParams, loadingFilters, selectedOrder } =
    orderStore;

  const handleBackClick = () => {
    setPagingParams({
      pageNumber: 1,
      pageSize: orderStore.pagingParams.pageSize,
    });
    navigate("/user/myorders");
  };

  const handleAddItemsToOrder = () => {
    if (selectedOrder) {
      const serializedOrder = JSON.parse(JSON.stringify(selectedOrder));
      navigate(
        `/user/addorderitem?orderId=${selectedOrder.id}&clientId=${selectedOrder.client.id}`,
        {
          state: { order: serializedOrder },
        }
      );
    }
  };

  useEffect(() => {
    loadOrderById(parseFloat(id!));
  }, [loadOrderById, id]);

  if (loadingFilters) return <CircularProgress />;

  const orderId = id ? parseFloat(id) : selectedOrder?.id;
  const orderApproved = selectedOrder ? selectedOrder.status > 1 : false;

  return (
    <div>
      <h1>Order Items</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h2>Order Number: {selectedOrder?.orderNumber}</h2>
        <Box>
          {!orderApproved && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddItemsToOrder}
              sx={{ marginRight: 2 }}
            >
              Add Items to Order
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={handleBackClick}>
            Back to Orders List
          </Button>
        </Box>
      </Box>
      <MyOrderItemList orderId={orderId!} orderApproved={orderApproved} />
    </div>
  );
};

export default observer(OrderItemDashboard);
