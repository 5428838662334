import { makeAutoObservable, runInAction } from "mobx";
import { Tax, TaxFormValues } from "../models/tax.ts";
import agent from "../api/agent.ts";
import { Pagination, PagingParams } from "../models/pagination.ts";

export default class TaxStore {
  taxes: Tax[] = [];
  selectedTax: Tax | undefined = undefined;
  editMode = false;
  loadingInitial = true;
  submitting = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  isActiveFilter = true;
  nameFilter = "";

  constructor() {
    makeAutoObservable(this);
  }

  loadTaxes = async () => {
    // this.setLoadingInitial(true)
    try {
      // Create a new URLSearchParams instance
      let params = new URLSearchParams();

      // Append the filter values to the query string
      if (this.nameFilter) params.append("name", this.nameFilter);
      params.append("isActive", String(this.isActiveFilter));
      params.append("pageNumber", this.pagingParams.pageNumber.toString());
      params.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.Taxes.list(params);

      runInAction(() => {
        this.setTaxes(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  getTaxById = (id: number) => {
    return this.taxes.find((tax) => tax.id === id);
  };

  saveTax = async (tax: TaxFormValues) => {
    this.submitting = true;
    try {
      await agent.Taxes.create(tax);
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  updateTax = async (tax: TaxFormValues) => {
    this.submitting = true;
    try {
      await agent.Taxes.edit(tax);
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  loadTaxById = async (id: number) => {
    let tax = this.getTaxById(id);
    if (tax) {
      this.selectedTax = tax;
      return tax;
    } else {
      this.loadingInitial = true;
      try {
        tax = await agent.Taxes.details(id);
        runInAction(() => {
          this.selectedTax = tax;
          this.loadingInitial = false;
        });
        return tax;
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  deleteTax = async (id: number) => {
    this.submitting = true;
    try {
      await agent.Taxes.delete(id);
      runInAction(() => {
        this.taxes = this.taxes.filter(
          (tax) => tax.id !== id
        );
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  setEditMode = (editMode: boolean) => {
    this.editMode = editMode;
  };

  setSubmitting = (submitting: boolean) => {
    this.submitting = submitting;
  };

  setLoadingInitial = (loadingInitial: boolean) => {
    this.loadingInitial = loadingInitial;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setNameFilter = (value: string) => {
    this.nameFilter = value;
  };

  setIsActiveFilter = (value: boolean) => {
    this.isActiveFilter = value;
  };

  setTaxes = (taxes: Tax[]) => {
    this.taxes = taxes;
  };

  clearSelectedTax = () => {
    this.selectedTax = undefined;
  };
}