import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { Order, OrderStatus } from "../../../../app/models/order.ts";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { OrderItem } from "../../../../app/models/orderItem.ts";
import agent from '../../../../app/api/agent.ts';
//Added by Riniyad - requester Tony - to handle export to csv
const exportToExcel = (order: Order) => {
  const { orderNumber, orderDate, client, userName, itemsCount, orderItems } =
    order;

  const data: any[][] = [];

  // Add order-level information in two columns (Label and Value)
  data.push(["Order Number", orderNumber]);
  data.push(["Order Date", formatDate(orderDate)]);
  data.push(["Client", client.name]);
  data.push(["Sales Agent", userName]);
  data.push(["Total Items", itemsCount]);
  data.push(["Order Total", order.subTotal]);

  // a blank row to separate order info from order items
  data.push([]);

  // header row for order items
  data.push([
    "Product Code",
    "Product Name",
    "Price",
    "Quantity",
    "Case/Unit",
    "Total Price",
  ]);

  // Add each order
  orderItems.forEach((item: OrderItem) => {
    data.push([
      item.product.code,
      item.product.name,
      item.unitPrice,
      item.quantity,
      item.byCase ? "case" : "unit",
      item.totalPrice,
    ]);
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Order Details");
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buffer], { type: "application/octet-stream" });

  saveAs(blob, `${orderNumber}.xlsx`);
};

const generateIMPFile = (order) => {
  if (!order || !order.orderItems || order.orderItems.length === 0) {
    console.error("Order or order items are not available");
    return;
  }

  let impFileContent = "";

  // 1. Add version section
  impFileContent += `<Version>\n"12001", "1"\n</Version>\n\n`;

  // 2. Add invoice details (SalInvoice section)
  const formattedDate = dayjs().format("MM-DD-YY"); // format date as MM-DD-YY
  impFileContent += `<SalInvoice>\n`;

  // Client details
  impFileContent += `"${order.client.name}", , ,, ,, , ,, , , ,\n`;
  const distinctProductCount = new Set(
    order.orderItems.map((item) => item.product.code)
  ).size;
  // Order details
  impFileContent += `"${distinctProductCount}","${
    order.orderNumber
  }", ,"${formattedDate} ","0", ,"${order.subTotal.toFixed(2)}","0.00"\n`;

  // Item details for each item
  order.orderItems.forEach((item) => {
    impFileContent += `"${item.product.code}","${item.quantity.toFixed(
      4
    )}","${item.unitPrice.toFixed(4)}","${item.totalPrice.toFixed(2)}"`;

    if (item.taxMappings) {
      item.taxMappings.forEach((mapping) => {
        const rate =
          mapping.overrideRate === null
            ? mapping.tax.rate
            : mapping.overrideRate;
        const amount = (item.totalPrice * rate) / 100.0;
        impFileContent += `,"${mapping.tax.code}","${
          mapping.tax.isIncluded ? "1" : "0"
        }","${mapping.tax.isRefunded ? "1" : "0"}","${rate.toFixed(
          3
        )}","${amount.toFixed(2)}"`;
      });
    }

    impFileContent += "\n";
  });

  impFileContent += `</SalInvoice>\n`;

  // Create a Blob from the content and trigger file download
  const blob = new Blob([impFileContent], { type: "text/plain;charset=utf-8" });
  saveAs(blob, `${order.orderNumber}.imp`);
};

const OrderList: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrders,
    approveOrder,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    isApprovedFilter,
  } = orderStore;
  const navigate = useNavigate();

  const [outOfStockDialog, setOutOfStockDialog] = useState({
    open: false,
    items: [] as any[]
  });

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrders();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const handleToggle = async (order) => {
    try {
      if (loadingInitial) return;

      const stockCheckResult = await orderStore.checkOrderStock(order);
      
      if (!stockCheckResult.success) {
        setOutOfStockDialog({
          open: true,
          items: stockCheckResult.outOfStockItems
        });
        
        await orderStore.updateOrderStatus(order, OrderStatus.OutOfStock);
      } else {
        await approveOrder(order);
        
        // Automatically assign lots after approval
        try {
          await agent.Orders.assignLots(order);
          toast.success('Order approved and lots assigned successfully');
        } catch (error) {
          console.error('Error assigning lots:', error);
          toast.error('Order approved but failed to assign lots');
        }
      }
      
      await loadOrders();
    } catch (error) {
      console.error('Error processing order:', error);
      toast.error('An error occurred while processing the order');
    }
  };

  const handleRevoke = async (id: number) => {
    try {
      if (loadingInitial) return;
      await orderStore.revokeOrder(id);
      await loadOrders();
    } catch (error) {
      console.error('Error revoking order:', error);
      toast.error('An error occurred while revoking the order');
    }
  };

  const handleDeleteOrder = async (orderId: number) => {
    try {
      if (loadingInitial) return;
      await orderStore.deleteOrder(orderId);
      await loadOrders();
    } catch (error) {
      console.error('Error deleting order:', error);
      toast.error('An error occurred while deleting the order');
    }
  };

  const handleAddItemstoOrder = (order: Order) => {
    const serializedOrder = JSON.parse(JSON.stringify(order));
    navigate(
      `/admin/addorderitem?orderId=${order.id}&clientId=${order.client.id} `,
      { state: { order: serializedOrder } }
    );
  };

  const orderStatusLabel = (status) => {
    // console.log(status);
    switch (status) {
      case 0:
        return "Waiting for Approval";
      case 1:
        return "Revoked";
      case 2:
        return "Order Received";
      case 3:
        return "Preparing";
      case 4:
        return "On Hold";
      case 5:
        return "Shipped";
      case 6:
        return "Delivered";
      case 7:
        return "Delayed";
      case 8:
        return "Approved";
      case 9:
        return "Waiting to ship";
      case 10:
        return "Out of Stock";
      default:
        return "Unknown";
    }
  };
  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const handleCloseDialog = () => {
    setOutOfStockDialog({
      open: false,
      items: []
    });
  };

  return (
    <div>
      {loadingInitial ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="customized table">
              <TableHead sx={{ bgcolor: "#525252" }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Order Number
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Order Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Client
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      User
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Sub Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Items
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" sx={{ color: "white" }}>
                      Order Items
                    </Typography>
                  </TableCell>
                  {isApprovedFilter ? (
                    <>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Approve
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Add Items to Order
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Delete Order
                        </Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Revoke
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Export to Excel
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" sx={{ color: "white" }}>
                          Generate .IMP File
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders &&
                  orders.map((order) => (
                    <TableRow
                      key={order.id}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      <TableCell>{order.orderNumber}</TableCell>
                      <TableCell>{formatDate(order.orderDate)}</TableCell>
                      <TableCell>{order.client.name}</TableCell>
                      <TableCell>{order.userName}</TableCell>
                      <TableCell>${order.subTotal}</TableCell>
                      <TableCell>{order.itemsCount}</TableCell>
                      <TableCell>{orderStatusLabel(order.status)}</TableCell>

                      <TableCell>
                        <IconButton component={Link} to={`${order.id}`}>
                          <OpenInNewIcon />
                        </IconButton>
                      </TableCell>

                      {!isApprovedFilter ? (
                        <>
                          <TableCell>
                            <Button
                              onClick={() => handleRevoke(order.id!)}
                              disabled={order.status === OrderStatus.Shipped}
                            >
                              Revoke
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => exportToExcel(order)}
                            >
                              Export
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => generateIMPFile(order)}
                            >
                              .IMP
                            </Button>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>
                            <Button onClick={() => handleToggle(order.id!)}>
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleAddItemstoOrder(order)}
                            >
                              Add Items
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleDeleteOrder(order.id!)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Pagination
              count={pagination?.totalPages ?? 0}
              page={pagination?.currentPage ?? 1}
              onChange={handlePageChange}
            />
          </Box>
        </>
      )}

      <Dialog 
        open={outOfStockDialog.open} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle 
          sx={{ 
            bgcolor: '#525252', 
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">
              Insufficient Stock Alert
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
            The following items don't have sufficient stock to fulfill the order:
          </Typography>
          <List>
            {outOfStockDialog.items.map((item, index) => (
              <ListItem 
                key={index}
                sx={{
                  mb: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: 1,
                  backgroundColor: '#f8f8f8'
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1
                  }}>
                    <Typography variant="h6" color="primary">
                      {item.product.name}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Code: {item.product.code}
                    </Typography>
                  </Box>
                  
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#e3f2fd',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Requested
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {item.quantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#fff3e0',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Available
                        </Typography>
                        <Typography variant="h6" color="warning.main">
                          {item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                    
                    <Grid item xs={4}>
                      <Paper 
                        elevation={0} 
                        sx={{ 
                          p: 1.5, 
                          backgroundColor: '#ffebee',
                          textAlign: 'center'
                        }}
                      >
                        <Typography variant="subtitle2" color="text.secondary">
                          Shortage
                        </Typography>
                        <Typography variant="h6" color="error">
                          {item.quantity - item.availableQuantity}
                          <Typography component="span" variant="body2" sx={{ ml: 0.5 }}>
                            {item.byCase ? 'cases' : 'units'}
                          </Typography>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, borderTop: '1px solid #e0e0e0' }}>
          <Button 
            onClick={handleCloseDialog} 
            variant="contained"
            size="large"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default observer(OrderList);
