export interface ICategory {
  id: number;
  name: string;
  description: string;
  imageUrl?: string;
  isActive: boolean;
}

export class Category implements ICategory {
  id: number;
  name: string;
  description: string;
  imageUrl?: string;
  isActive: boolean;

  constructor(init: CategoryFormValues) {
    Object.assign(this, init);
    this.isActive = true;
  }
}

export class CategoryFormValues {
  id?: number;
  name: string = "";
  description: string = "";
  imageUrl?: string = "";

  constructor(category?: CategoryFormValues) {
    if (category) {
      this.id = category.id;
      this.name = category.name;
      this.description = category.description;
      this.imageUrl = category.imageUrl ?? "";
    }
  }
}