export interface ITax {
  id: number;
  name: string;
  code: string;
  isIncluded: boolean;
  isRefunded: boolean;
  rate: number;
}

export class Tax implements ITax {
  id: number;
  name: string;
  code: string;
  isIncluded: boolean;
  isRefunded: boolean;
  rate: number;

  constructor(init: TaxFormValues) {
    Object.assign(this, init);
  }
}

export class TaxFormValues {
  id?: number;
  name: string = "";
  code: string = "";
  isIncluded: boolean = false;
  isRefunded: boolean = false;
  rate: number = 0;

  constructor(tax?: TaxFormValues) {
    if (tax) {
      this.id = tax.id;
      this.name = tax.name;
      this.code = tax.code;
      this.isIncluded = tax.isIncluded;
      this.isRefunded = tax.isRefunded;
      this.rate = tax.rate;
    }
  }
}