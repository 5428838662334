import { useField } from "formik";
import { Box, FormControlLabel, Switch } from "@mui/material";
import React from "react";

interface MyBoolInputProps {
  label: string;
  name: string;
  disabled?: boolean;
  defaultValue?: boolean;
}

const MyBoolInput: React.FC<MyBoolInputProps> = ({
  label,
  name,
  disabled
}) => {
  const [field, meta] = useField(name);
  let id = name;
  return (
    <Box
      sx={{
        width: 600,
        maxWidth: "100%",
      }}
    >
      <FormControlLabel control={ 
        <Switch
          {...field}
          id={id}
          disabled={disabled}
          checked={field.value}
        />
      } 
      label={label}
      />      
    </Box>
  );
};

export default MyBoolInput;
