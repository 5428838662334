import { Category } from "./category";
import { Province } from "./province";
import { Tax } from "./tax";

export interface ITaxMapping {
  id: number;
  provinceId: number;
  province: Province;
  categoryId: number;
  category: Category;
  taxId: number;
  tax: Tax;
  overrideRate?: number; 
}

export class TaxMapping implements ITaxMapping {
  id: number;
  provinceId: number;
  province: Province;
  categoryId: number;
  category: Category;
  taxId: number;
  tax: Tax;
  overrideRate?: number; 

  constructor(init: TaxMappingFormValues) {
    Object.assign(this, init);
  }
}

export class TaxMappingFormValues {
  id?: number;
  provinceId?: number = undefined;
  categoryId?: number = undefined;
  taxId?: number = undefined;;
  overrideRate?: number = undefined; 

  constructor(taxMapping?: TaxMappingFormValues) {
    if (taxMapping) {
      this.id = taxMapping.id;
      this.provinceId = taxMapping.provinceId;
      this.categoryId = taxMapping.categoryId;
      this.taxId = taxMapping.taxId;
      this.overrideRate = taxMapping.overrideRate;
    }
  }
}