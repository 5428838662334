import { makeAutoObservable, runInAction } from "mobx";
import { TaxMapping, TaxMappingFormValues } from "../models/taxMapping.ts";
import agent from "../api/agent.ts";
import { Pagination, PagingParams } from "../models/pagination.ts";
import { Category } from "../models/category.ts";
import { Province } from "./clientStore.ts";
import { Tax } from "../models/tax.ts";

export default class TaxMappingStore {
  taxMappings: TaxMapping[] = [];
  categories: Category[] = [];
  provinces: Province[] = [];
  taxes: Tax[] = [];
  selectedTaxMapping: TaxMapping | undefined = undefined;
  editMode = false;
  loadingInitial = true;
  loadingFilters = true;
  submitting = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  isActiveFilter = true;
  categoryNameFilter = "";
  provinceNameFilter = "";
  taxNameFilter = "";

  constructor() {
    makeAutoObservable(this);
  }

  loadTaxMappings = async () => {
    // this.setLoadingInitial(true)
    try {
      // Create a new URLSearchParams instance
      let params = new URLSearchParams();

      // Append the filter values to the query string
      if (this.categoryNameFilter) params.append("categoryName", this.categoryNameFilter);
      if (this.provinceNameFilter) params.append("provinceName", this.provinceNameFilter);
      if (this.taxNameFilter) params.append("taxName", this.taxNameFilter);
      
      params.append("isActive", String(this.isActiveFilter));
      params.append("pageNumber", this.pagingParams.pageNumber.toString());
      params.append("pageSize", this.pagingParams.pageSize.toString());

      const response = await agent.TaxMappings.list(params);

      runInAction(() => {
        this.setTaxMappings(response.data);
        this.setPagination(response.pagination);
        this.setLoadingInitial(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  loadActiveCategories = async () => {
    try {
      const params = new URLSearchParams();
      params.append("isActive", "true");

      const response = await agent.Categories.list(params);

      runInAction(() => {
        this.setCategories(response.data);
        this.setLoadingFilters(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingFilters(false);
      });
    }
  };

  loadProvinces = async () => {
    try {
      const params = new URLSearchParams();

      const response = await agent.Provinces.list();

      runInAction(() => {
        this.setProvinces(response);
        this.setLoadingFilters(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingFilters(false);
      });
    }
  };

  loadTaxes = async () => {
    try {
      const params = new URLSearchParams();

      const response = await agent.Taxes.list(params);

      runInAction(() => {
        this.setTaxes(response.data);
        this.setLoadingFilters(false);
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.setLoadingFilters(false);
      });
    }
  };

  getTaxMappingById = (id: number) => {
    return this.taxMappings.find((taxMapping) => taxMapping.id === id);
  };

  saveTaxMapping = async (taxMapping: TaxMappingFormValues) => {
    this.submitting = true;
    try {
      await agent.TaxMappings.create(taxMapping);
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  updateTaxMapping = async (taxMapping: TaxMappingFormValues) => {
    this.submitting = true;
    try {
      await agent.TaxMappings.edit(taxMapping);
      this.setSubmitting(false);
      this.setEditMode(false);
    } catch (error) {
      console.log(error);
      this.setSubmitting(false);
    }
  };

  loadTaxMappingById = async (id: number) => {
    let taxMapping = this.getTaxMappingById(id);
    if (taxMapping) {
      this.selectedTaxMapping = taxMapping;
      return taxMapping;
    } else {
      this.loadingInitial = true;
      try {
        taxMapping = await agent.TaxMappings.details(id);
        runInAction(() => {
          this.selectedTaxMapping = taxMapping;
          this.loadingInitial = false;
        });
        return taxMapping;
      } catch (error) {
        console.log(error);
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  deleteTaxMapping = async (id: number) => {
    this.submitting = true;
    try {
      await agent.TaxMappings.delete(id);
      runInAction(() => {
        this.taxMappings = this.taxMappings.filter(
          (taxMapping) => taxMapping.id !== id
        );
        this.submitting = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.submitting = false;
      });
    }
  };

  setEditMode = (editMode: boolean) => {
    this.editMode = editMode;
  };

  setSubmitting = (submitting: boolean) => {
    this.submitting = submitting;
  };

  setLoadingInitial = (loadingInitial: boolean) => {
    this.loadingInitial = loadingInitial;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setCategoryNameFilter = (value: string) => {
    this.categoryNameFilter = value;
  };

  setProvinceNameFilter = (value: string) => {
    this.provinceNameFilter = value;
  };

  setTaxNameFilter = (value: string) => {
    this.taxNameFilter = value;
  };

  setTaxMappings = (taxMappings: TaxMapping[]) => {
    this.taxMappings = taxMappings;
  };

  setCategories = (categories: Category[]) => {
    this.categories = categories;
  };

  setProvinces = (provinces: Province[]) => {
    this.provinces = provinces;
  };

  setTaxes = (taxes: Tax[]) => {
    this.taxes = taxes;
  };

  setLoadingFilters = (loadingFilters: boolean) => {
    this.loadingFilters = loadingFilters;
  };

  clearSelectedTaxMapping = () => {
    this.selectedTaxMapping = undefined;
  };
}