export interface ProductLot {
  id: number;
  productId: number;
  lotNumber: string;
  sku?: string;
  unitPrice: number;
  unitWeight: number;
  itemsInCase: number;
  casePrice: number;
  notes?: string;
  createdAt: Date;
  updatedAt: Date;
  isActive: boolean;
  caseQuantity: number;
  unitQuantity: number;
  warehouseLocation?: string;
  expiryDate?: Date;
}

export class ProductLotClass implements ProductLot {
  constructor(
    public id: number,
    public productId: number,
    public lotNumber: string,
    public sku: string | undefined,
    public unitPrice: number,
    public unitWeight: number,
    public itemsInCase: number,
    public casePrice: number,
    public notes: string | undefined,
    public createdAt: Date,
    public updatedAt: Date,
    public isActive: boolean,
    public caseQuantity: number,
    public unitQuantity: number,
    public warehouseLocation: string | undefined,
    public expiryDate: Date,
  ) {}
}

export type CreateProductLotDto = Omit<ProductLot, 'id'>; 