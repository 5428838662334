import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
  Box,
  CircularProgress,
  Button,
  Alert,
  Snackbar,
  TextField,
  IconButton,
} from "@mui/material";
import ShipmentIcon from "@mui/icons-material/LocalShipping";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStore } from "../../../../app/stores/store.ts";
import { observer } from "mobx-react-lite";
import { formatDate } from "../../../../app/utils/dateUtils.js";
import { OrderStatus } from "../../../../app/models/order.ts";
import agent from "../../../../app/api/agent.ts";
import { useNavigate } from "react-router-dom";

const ShipManagement: React.FC = () => {
  const { orderStore } = useStore();
  const {
    setPagingParams,
    loadOrdersForShipment,
    pagination,
    orders,
    pagingParams,
    loadingInitial,
    setOrders,
  } = orderStore;

  const navigateToHome = (pageNumber: number) => {
    setPagingParams({
      pageNumber: pageNumber,
      pageSize: pagingParams.pageSize,
    });
    loadOrdersForShipment();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => navigateToHome(value);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isShipping, setIsShipping] = useState<{ [key: number]: boolean }>({});

  const navigate = useNavigate();

  const handleShipOrder = async (orderId: number) => {
    setIsShipping((prev) => ({ ...prev, [orderId]: true }));
    try {
      const shipmentDate = new Date();
      await agent.Orders.updateOrderStatus(orderId, OrderStatus.Shipped);
      await agent.Orders.updateShipmentDate(orderId, shipmentDate);

      setOrders(
        orders.map((order) =>
          order.id === orderId
            ? {
                ...order,
                status: OrderStatus.Shipped,
                shipmentDate: shipmentDate,
              }
            : order
        )
      );
      setSuccessMessage("Order shipped successfully");
    } catch (error) {
      console.error("Error shipping order:", error);
      setErrorMessage("Failed to ship order");
    } finally {
      setIsShipping((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  useEffect(() => {
    loadOrdersForShipment();
  }, [loadOrdersForShipment]);

  const [filters, setFilters] = useState({
    orderNumber: '',
    clientName: '',
    shipmentDateFrom: null as Date | null,
    shipmentDateTo: null as Date | null
  });

  if (loadingInitial) return <CircularProgress />;    const handleFilterChange = (filterName: string, value: any) => {
    const newFilters = {
      ...filters,
      [filterName]: value
    };
    setFilters(newFilters);

    const params = new URLSearchParams();
    if (newFilters.orderNumber) {
      params.append("OrderNumber", newFilters.orderNumber);
    }
    if (newFilters.clientName) {
      params.append("ClientName", newFilters.clientName);
    }
    if (newFilters.shipmentDateFrom) {
      params.append("ShipmentDateFrom", newFilters.shipmentDateFrom);
    }
    if (newFilters.shipmentDateTo) {
      params.append("ShipmentDateTo", newFilters.shipmentDateTo);
    }
    
    setPagingParams({
      pageNumber: 1,
      pageSize: pagingParams.pageSize,
    });
    loadOrdersForShipment(params);
  };

  return (
    <div>
      <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <TextField
          label="Order Number"
          variant="outlined"
          size="small"
          value={filters.orderNumber}
          onChange={(e) => handleFilterChange('orderNumber', e.target.value)}
        />
        <TextField
          label="Client Name"
          variant="outlined"
          size="small"
          value={filters.clientName}
          onChange={(e) => handleFilterChange('clientName', e.target.value)}
        />
        <TextField
          label="Shipment Date From"
          type="date"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          value={filters.shipmentDateFrom || ''}
          onChange={(e) => handleFilterChange('shipmentDateFrom', e.target.value)}
        />
        <TextField
          label="Shipment Date To"
          type="date"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          value={filters.shipmentDateTo || ''}
          onChange={(e) => handleFilterChange('shipmentDateTo', e.target.value)}
        />
      </Box>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setErrorMessage(null)}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSuccessMessage(null)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead sx={{ bgcolor: "#525252" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Number
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Order Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Client
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Sales Rep
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Sub Total
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Items
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Shipment Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Actions
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" sx={{ color: "white" }}>
                  View Shipment
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                    backgroundColor: order.status === 9 ? "#ffebee" : "inherit",
                    "& .MuiTableCell-root": {
                      color: order.status === 9 ? "#d32f2f" : "inherit",
                    },
                  }}
                >
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{formatDate(order.orderDate)}</TableCell>
                  <TableCell>{order.client.name}</TableCell>
                  <TableCell>{order.userName}</TableCell>
                  <TableCell>${order.subTotal}</TableCell>
                  <TableCell>{order.itemsCount}</TableCell>
                  <TableCell>{OrderStatus[order.status]}</TableCell>
                  <TableCell>
                    {order.shipmentDate
                      ? formatDate(order.shipmentDate)
                      : "Not Available"}
                  </TableCell>
                  <TableCell>
                    {order.status === 9 && (
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<ShipmentIcon />}
                        onClick={() => handleShipOrder(order.id)}
                        disabled={isShipping[order.id]}
                      >
                        {isShipping[order.id] ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Ship Order"
                        )}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => navigate(`/admin/warehouse/view-shipment/${order.id}`)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Pagination
          count={pagination ? pagination.totalPages : 0}
          page={pagination ? pagination.currentPage : 1}
          onChange={handlePageChange}
        />
      </Box>
    </div>
  );
};

export default observer(ShipManagement);
