export const ADMIN_MENU_ITEMS = [
  "Home",
  "Users",
  "Clients",
  "Categories",
  "Manufacturers",
  "Products",
  "Orders",
  "Taxes",
  "Tax Mappings",
  "Warehouse"
];

export const USER_MENU_ITEMS = ["Home", "PlaceOrder", "MyOrders"];
