import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Router } from './app/router/Router.tsx'
import { StoreContext, store } from './app/stores/store.ts'
import { CircularProgress } from '@mui/material'

const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <React.StrictMode>
      <StoreContext.Provider value={store}>
        <Suspense fallback={<CircularProgress />}>
          <RouterProvider router={Router} />
        </Suspense>
      </StoreContext.Provider>
    </React.StrictMode>
  )
}
